import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  name: 'FullScreenPresenter',
  props: {
    padded: Boolean,
  },
  data() {
    return {
      open: false,
      view: null,
    }
  },
  methods: {
    async show() {
      this.open = true
      document.addEventListener('keyup', this.keydown)
      await this.$nextTick()
      this.view = this.$refs.FullScreenPresenter
      disableBodyScroll(this.view)
    },
    hide() {
      this.open = false
      document.removeEventListener('keyup', this.keydown)
      enableBodyScroll(this.view)
    },

    keydown(event) {
      // ESC
      if (event.keyCode === 27) {
        this.hide()
      }
    },
  },
}
